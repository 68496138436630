<template>
  <!-- <pre>
  {{brand_product_styles}}
  </pre>

  <pre>
    {{ new_style_variables }}
  </pre> -->

  <div v-show="styles_loaded" :class="setbrandid" :style="new_style_variables" v-cloak>
    <Header
      :logo_link="logo_link"
      :home_link="home_link"
      :header_menu="header_menu"
      :show_basket="show_basket"
      :show_home="true"
      :show_languages="true"
      :basket_number="0"
      :logo_image="logo_image"
      :lang="lang.toUpperCase()"
      :translations="translations"
      :brandSlug="setbrandid"
      :highlight_tab_item="1"
      :basket_is_model="true"
    ></Header>

    <div class="page-bg" :style="set_style_variables" v-if="brandloaded">
      <!-- <Basket
      :passedclass="``"
      :positionTop="`24px`"
      :position="`fixed`"
      :showButton="true"
      :lang="`EN`"
      :key="basketKey"
      :hasItems="true"
      :totalfee="100"
      :bookingfee="0"
    ></Basket> -->

      <!-- <pre>
        {{this.fetch_images}}
      </pre> -->

      <div class="hero-section">
        <template v-for="hero in this.fetch_images" :key="hero">
          <div
            class="hero-bg-desktop"
            :style="`opacity: ${
              hero === this.hero_desktop_background ? '1' : '0'
            }; background-image: url(${hero}); transition: opacity ${fade_transition}s ease;`"
          ></div>
        </template>

        <div class="hero-overlay"></div>

        <div class="hero-bg-mobile">
          <!-- this shows on both desktop and mobile -->

          <template v-if="setbrandid === 'OKC'">
            <div class="hero-text subheading-alt">
              <h1>{{ getrans(banner_heading) || "" }}</h1>
              <span>{{ getrans(banner_subheading) || "" }}</span> <br />
              <span>
                {{ "Official Airport Parking" }}
              </span>
            </div>
          </template>
          <template v-else>
            <div class="hero-text">
              <h1>{{ getrans(banner_heading) || "" }}</h1>
              <p>{{ getrans(banner_subheading) || "" }}</p>
            </div>
          </template>
        </div>
        <div class="search-widget-desktop">
          <SearchWidget
            v-if="tab_param && setbrandid && setbrandid === 'FM1'"
            :brand_slug="setbrandid"
            showTabs="parking, lounge, fasttrack, coach, travel, flights, holidays, hotels, tours"
            :activeTab="'tours'"
            :lang="lang"
            custom_class="dark-mobile"
            fetch_images="true"
          />
          <SearchWidget
            v-else-if="tab_param && setbrandid && setbrandid === 'PV1'"
            :brand_slug="setbrandid"
            showTabs="parking"
            :activeTab="'parking'"
            :lang="lang"
            custom_class="dark-mobile"
            fetch_images="true"
            hide-tabs="true"
          />
          <SearchWidget
            v-else-if="tab_param && setbrandid && setbrandid === 'JER'"
            :brand_slug="setbrandid"
            showTabs="parking, fasttrack"
            :activeTab="'fasttrack'"
            :lang="lang"
            custom_class="dark-mobile"
            fetch_images="true"
            hide-tabs="true"
          />
          <SearchWidget
            v-else-if="(tab_param && setbrandid && setbrandid === 'ADT')"
            :brand_slug="setbrandid"
            showTabs="flights"
            :activeTab="'flights'"
            :lang="lang"
            custom_class="dark-mobile"
            fetch_images="true"
            hide-tabs="true"
          />
          <SearchWidget
            v-else-if="tab_param && setbrandid"
            :brand_slug="setbrandid"
            showTabs="parking, lounge, fasttrack, coach, travel, flights, holidays, hotels, tours, immigration"
            :activeTab="tab_param"
            :lang="lang"
            custom_class="dark-mobile"
            fetch_images="true"
          />
        </div>
      </div>

      <PanelsGroup
        v-cloak
        v-if="panels_group && lang && translations && setbrandid"
        :panels_group="panels_group"
        :lang="lang"
        :translations="translations"
        :setbrandid="setbrandid"
      >
      </PanelsGroup>
    </div>

    <!-- <v-other></v-other> -->

    <!-- <v-searchflights 
    custom-class="tabs-left large-strings dark-mobile alt-inputs" adults-select="1" children-select="0"
    infants-select="0" senior-select="0"
    show-tabs="flights" hide-tabs="false"
    active-tab="flights" brand-theme="light" brand-slug="BHX" button-text="Get a Quote ➜" custom-lang="en"
    use12h="false" results-domain="BHX"
      ></v-searchflights> -->

    <iframe
      v-if="setbrandid === 'IP1'"
      src="https://www.google.com/maps/embed?key=AIzaSyDMEwQL_NnDYpiw8MmOjtk-paNW6sdFer8&pb=!1m18!1m12!1m3!1d1119.4885961212276!2d-4.424163938017138!3d55.86306211975774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48884f1db3e3dfef%3A0x5d81f0fbe2160841!2siPark%20Glasgow!5e0!3m2!1sen!2suk!4v1655227726095!5m2!1sen!2suk"
      width="100%"
      height="300"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    >
    </iframe>

    <!-- <pre>
  {{ brand_product_styles }}
  </pre> -->

    <Footernwi
      v-if="setbrandid === 'NWI'"
      :footer_links="footer_links"
      :footer_columns="footer_columns"
      :lang="lang.toUpperCase()"
      :translations="translations"
      :brandSlug="setbrandid"
    ></Footernwi>
    <Footer
      v-else
      :footer_links="footer_links"
      :footer_columns="footer_columns"
      :lang="lang.toUpperCase()"
      :translations="translations"
      :brandSlug="setbrandid"
    ></Footer>

    <!-- <LoginModel /> -->
    <BasketModel
      :lang="lang.toUpperCase()"
      :translations="translations"
      :brandSlug="setbrandid"
    ></BasketModel>

    <!-- <rez-ai v-if="setbrandid === 'HER'"></rez-ai> -->

    <Cookies :brandSlug="setbrandid" />
    <CookiesOld :brandSlug="setbrandid" v-if="setbrandid === 'none'" />
  </div>
</template>

<script>
const axios = require("axios");
const genkeys = require("rezauthlib");
var uap = require("ua-parser-js");
const { EncryptStorage } = require("encrypt-storage");
const encryptStorage = new EncryptStorage(process.env.VUE_APP_CHL, {
  storageType: "sessionStorage",
});
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import Footernwi from "../../components/FooterNWI.vue";
//import SearchWidget from "../../components/SearchWidget.vue";
import SearchWidget from "../../components/SearchWidgetCustomElement.vue";
import PanelsGroup from "../../components/PanelsGroup.vue";
import BasketModel from "../../components/BasketModel.vue";
const crypto = require("crypto");
import Cookies from "../../components/Cookies.vue";
import CookiesOld from "../../components/Cookies_old.vue";




//import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
// import Basket from "../../components/Basket.vue";
// import LoginModel from "../../components/LoginModel.vue";
// import { cssData } from "../../modules/cssData";
// import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { reactive } from "vue";

import md5 from "js-md5";
const localforage = require("localforage");
let store = localforage.createInstance({
  name: "tranlations",
});

import { set_style_vars } from "@/modules/set_style_vars";




export default {
  name: "App",
  components: {
    Header,
    Footer,
    Footernwi,
    SearchWidget,
    BasketModel,
    //LoginModel,
    Cookies,
    CookiesOld,
    PanelsGroup,
  },
  data() {
    return {
      ua: {},
      styles_loaded: false,
      tab_param: "",
      lang: "en",
      translations: "",
      setbrandid: "",
      currency: "",
      brandloaded: false,
      widgetloaded: false,
      css_data: undefined,
      banner_heading: "",
      banner_subheading: "",
      brand_data: undefined,
      brand_data_key: 0,
      brand_product_styles: reactive({}),
      brand_product_settings: {},
      brand_products: {},
      hero_desktop_background: "",
      hero_mobile_background: "",
      logo_image: "",
      hero_font_size_h1: "3rem",
      hero_font_size_h1_mobile: "2rem",
      hero_font_size_p: "2rem",
      hero_font_size_p_mobile: "1rem",
      hero_text_align: "center",
      hero_text_align_mobile: undefined,
      use_local_data: false,
      header_menu: [],
      footer_links: [],
      logo_link: { mtype: "Internal", url: "/" },
      home_link: undefined,
      panels_background_color: "#d6d6d6",
      panels_group: undefined,
      panel_columns: 1,
      footer_columns: "1 Row",
      show_basket: false,
      fetch_images: [],
      fade_transition: "0.5",
      partner_id: "",
      partner_name: "",
      campaign_id: "",
      campaign_name: "",
      search_origin: "",
    };
  },
  computed: {
    new_style_variables() {
      return set_style_vars(this.brand_product_styles);
    },
    set_style_variables: function () {
      return {
        "--hero-background-mobile": `url(${this.hero_desktop_background})`,
        // "--hero-background-desktop": this.hero_mobile_background
        //   ? `url(${this.hero_mobile_background})`
        //   : `url(${this.hero_desktop_background})`,
        "--hero-font-size-h1": this.hero_font_size_h1,
        "--hero-font-size-h1-mobile": this.hero_font_size_h1_mobile,
        "--hero-font-size-p": this.hero_font_size_p,
        "--hero-font-size-p-mobile": this.hero_font_size_p_mobile,
        "--hero-text-align": this.hero_text_align,
        "--hero-text-align-mobile":
          this.hero_text_align_mobile || this.hero_text_align,
        "--panel-background-color": this.panels_background_color,
      };
    },
  },
  methods: {
    setStyles(obj) {
      /* eslint-disable */
      if (!obj) return;
obj.forEach((item) => {
        for (const [classname, style] of Object.entries(item)) {
          this.brand_product_styles[classname] = style;
        }
      });
      //console.log(this.brand_product_styles["asset_image_1"]);
      this.logo_image = this.brand_product_styles["asset_image_1"];
      // this.hero_font_size_h1 =
      //   this.brand_product_styles["panel_heading_font_size"];
      // this.hero_font_size_h1_mobile =
      //   this.brand_product_styles["panel_heading_font_size"];
      //console.log(this.hero_font_size_h1);
      // this.hero_font_size_p = this.brand_product_styles["panel_text_font_size"];
      this.hero_font_size_p_mobile =
        this.brand_product_styles["panel_text_font_size"];

      if (this.setbrandid === "NWI") {
        this.fade_transition = "0";
      }

      if (this.setbrandid === "FM1") {
        this.hero_text_align = "left";
      }

      /* eslint-enable */
    },

    getrans(string) {
      var s = md5(string);
      var ss = this.setbrandid + "-" + this.lang.toUpperCase() + "-" + s;
      //console.log(ss);
      return this.translations[ss] || string;
    },
    getSessionData(name) {
      return JSON.parse(sessionStorage.getItem(name));
    },
    setSessionData(name, value) {
      if (value != null) sessionStorage.setItem(name, JSON.stringify(value));
      else sessionStorage.removeItem(name);
    },
    loadTranslations() {
      let vm = this;
      if (vm.getSessionData("language")) {
        vm.lang = vm.getSessionData("language");
      } else {
        vm.setSessionData("language", vm.lang);
      }

      const urlParams = new URLSearchParams(window.location.search);
      let param_lang = urlParams.get("lang");
      if (param_lang) {
        vm.lang = param_lang;
        vm.setSessionData("language", vm.lang);
      }

      store.getItem("tranlations").then((params) => {
        if (params !== null) {
          vm.translations = JSON.parse(params);
          //console.log("getTranscache");
          //console.log(vm.translations);
        } else {
          let trans_url =
            "https://devhub.rezcomm.com/hubapmydbauth/brandapi/parkingtrans.php?brandslug=";
          if (vm.setbrandid !== "HER") {
            trans_url =
              "https://devhub.rezcomm.com/hubapmydbauth/brandapi/devtrans.php?brandslug=";
          }

          axios.get(trans_url + vm.setbrandid).then((response) => {
            //console.log("getTranscache index");
            //console.log(response);
            vm.translations = JSON.parse(response.request.response);
            store.setItem("tranlations", JSON.stringify(vm.translations));
          });
        }
      });
    },

    tab_change_action(hero_settings) {
      this.hero_desktop_background = hero_settings.hero_image;
      this.hero_mobile_background = hero_settings.hero_image;
      this.banner_heading = this.decodeQuotes(hero_settings.hero_heading);
      this.banner_subheading = this.decodeQuotes(
        hero_settings.hero_sub_heading
      );
    },

    decodeQuotes(str) {
      return str.replace(/&#39;/g, "'");
    },

    getLocalData(name) {
      return JSON.parse(sessionStorage.getItem(name));
    },
    setLocalData(name, value) {
      if (value != null) sessionStorage.setItem(name, JSON.stringify(value));
      else sessionStorage.removeItem(name);
    },
    setBrandData() {
      this.brand_data = this.getLocalData("brand_data");
      this.setStyles(this.brand_data.brand_product_styles);
      this.brand_product_settings = this.brand_data.brand_product_settings;
      this.brand_products = this.brand_data.brand_products;
      this.brand_data_key++;
      this.styles_loaded = true;
    },
    getBrandData() {
      if (this.use_local_data && this.getLocalData("brand_data")) {
        this.setBrandData();
      } else {
        let secsa = genkeys(
          process.env.VUE_APP_BRNDDET_SECRETID,
          process.env.VUE_APP_BRNDDET_SECRETKEY
        );
        let b1s = secsa.b1s;
        let v2s = secsa.v2s;
        let a1 = secsa.a1;
        let v1 = secsa.v1;
        let c2s = secsa.c2s;
        let uidgen = secsa.uidgen;
        //console.log("uidgen", uidgen);

        axios
          .post(
            process.env.VUE_APP_DOMAIN_DEVHUB +
              "hubapmydbauth/brandapi/branddetailsdev.php?brandslug=" +
              this.setbrandid,
            "",
            {
              headers: {
                b1: b1s,
                v2: v2s,
                c2s: c2s,
                a1: a1,
                v1: v1,
                c1: uidgen,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            //console.log("getBrandData", response.data);
            this.setLocalData("brand_data", response.data);
            this.setMenu(response.data);
            this.createPanels(response.data);
            this.setBrandData();
          });
      }
    },
    gensessionid() {
      if (encryptStorage.getItem("search_analytics_id") === undefined) {
        let search_analytics_id = crypto.randomBytes(16).toString("base64");
        encryptStorage.setItem("search_analytics_id", search_analytics_id);
        return search_analytics_id;
      } else {
        return encryptStorage.getItem("search_analytics_id");
      }
    },
    utmdata() {
      let sessid = "";
      let analytics = "0";
      if (!this.$cookie.isCookieAvailable("session-id")) {
        this.gensessionid();
        sessid = encryptStorage.getItem("search_analytics_id");
      } else {
        sessid = this.$cookie.getCookie("session-id");
        analytics = "1";
      }
      if (this.$cookie.isCookieAvailable("utm_partner")) {
        let trackevents = { page: window.location.href, analytics: analytics };
        let tdata = {
          utm_campaign: this.$cookie.getCookie("utm_campaign"),
          utm_content: this.$cookie.getCookie("utm_content"),
          utm_medium: this.$cookie.getCookie("utm_medium"),
          utm_partner: this.$cookie.getCookie("utm_partner"),
          utm_source: this.$cookie.getCookie("utm_source"),
          utm_sub_partner: this.$cookie.getCookie("utm_sub_partner"),
          utm_term: this.$cookie.getCookie("utm_term"),
          session_id: sessid,
          trackevents: trackevents,
        };

        let secsa = genkeys(
          process.env.VUE_APP_UTM_CAMPAIGN_SECRETID,
          process.env.VUE_APP_UTM_CAMPAIGN_SECRETKEY
        );
        let b1s = secsa.b1s;
        let v2s = secsa.v2s;
        let a1 = secsa.a1;
        let v1 = secsa.v1;
        let c2s = secsa.c2s;
        let uidgen = secsa.uidgen;

        axios
          .post(
            process.env.VUE_APP_DOMAIN_DEVHUB +
              "hubapmydbauth/utm_campaign/camp.php",
            tdata,
            {
              headers: {
                b1: b1s,
                v2: v2s,
                c2s: c2s,
                a1: a1,
                v1: v1,
                c1: uidgen,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log("utmdata", response.data);
          });
      }
    },
    async getbranded() {
      await axios
        .post(
          process.env.VUE_APP_DOMAIN_DEVHUB +
            "hubapmydbauth/brandapi/getbrnslg_dev.php",
          "",
          {
            headers: {
              secret_id: process.env.VUE_APP_BRNDED_SECRETID,
              secret_key: process.env.VUE_APP_BRNDED_SECRETKEY,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log("response.data.slug", response.data.slug);
          //console.log("response.data.currency", response.data.currency);
          if (response.data.code == "1") {
            this.setbrandid = response.data.slug;
            this.currency = response.data.currency;
            this.brandloaded = true;
            this.getBrandData();
            this.loadTranslations();
          }
        });
    },

    setMenu(data) {
      if (!Array.isArray(data.brand_menu) || !data.brand_menu.length > 0) return; 
data.brand_menu.forEach((menu) => {
        if (menu.label.toLowerCase() === "home") {
          this.home_link = menu.url;
        }
        if (menu.label.toLowerCase() === "logo") {
          this.logo_link = menu;
        }
        menu.loggedin = true;
        menu.loggedout = true;
      });

      const top_menu = data.brand_menu.filter((menu) => menu.position === "T");
      this.createHeaderLinks(top_menu);

      const footer_menu = data.brand_menu.filter(
        (menu) => menu.position === "B"
      );
      this.createFooterLinks(footer_menu);
    },
    createHeaderLinks(data) {
      const header_menu = [];
      if (data.length > 0) {
        data.forEach((menu) => {
          if (menu.label.toLowerCase() === "basket") {
            this.show_basket = true;
          } else if (
            menu.label.toLowerCase() !== "home" &&
            menu.label.toLowerCase() !== "logo"
          ) {
            header_menu.push(menu);
          }
        });
      }
      this.header_menu = header_menu;
    },
    createFooterLinks(data) {
      this.footer_columns = data[0].mcolumn;
      this.footer_links = data.reduce((a, r) => {
        if (!a[r.mgroup]) a[r.mgroup] = { group: r.mgroup, data: [] };
        a[r.mgroup].data.push(r);
        return a;
      }, {});
    },
    createPanels(data) {
      let panels_data = data.brand_home_panels || [];
      panels_data.sort((a, b) => (a.rownum > b.rownum ? 1 : -1));
      panels_data = panels_data.reduce((a, r) => {
        if (!a[r.rownum]) a[r.rownum] = { row: r.rownum, columns: [] };
        a[r.rownum].columns.push(r);
        a[r.rownum].columns.sort(function (a, b) {
          var keyA = Number(a.colnum),
            keyB = Number(b.colnum);
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        return a;
      }, {});
      this.panels_group = panels_data;
    },
    setTabFromParam() {
      // uses the url param to set the widget tab
      const urlParams = new URLSearchParams(window.location.search);
      let param_tab = urlParams.get("tab");
      if (param_tab) {
        this.tab_param = param_tab;
        switch (this.tab_param) {
          case "cpk":
            this.tab_param = "parking";
            break;
          case "expresslane":
          case "ftt":
            this.tab_param = "fasttrack";
            break;
          case "lounge":
          case "lng":
            this.tab_param = "lounge";
            break;
          default:
            this.tab_param = "parking";
            break;
        }
      } else {
        this.tab_param = "parking";
      }
    },

    toggle_basket_model() {
      const new_event = new CustomEvent("basket_model_toggle", {
        detail: {
          data: "toggle",
        },
      });
      window.dispatchEvent(new_event);
    },
  },
  created() {},
  mounted() {
    this.getbranded();
    var ua = uap(navigator.userAgent);
    this.ua = ua;
    // let pa = ua.getResult();
    encryptStorage.setItem("uas", this.ua);
    console.log("ua", encryptStorage.getItem("uas"));
    // console.log('pa',pa);
    var hours = 1 / 2;
    var noww = new Date().getTime();
    var snddc = sessionStorage.getItem("snddc");
    if (snddc == null) {
      sessionStorage.setItem("snddc", noww);
    } else {
      if (noww - snddc > hours * 60 * 60 * 1000) {
        sessionStorage.clear();
        sessionStorage.setItem("snddc", noww);
      }
    }
    setTimeout(() => {
      this.widgetloaded = true;
      //sessionStorage.clear();
    }, 0);

    this.setTabFromParam();
    const urlParams = new URLSearchParams(window.location.search);
    console.log("urlParams", urlParams);
    if (urlParams.has("utm_partner")) {
      this.utm_partner = urlParams.get("utm_partner");
    }
    if (urlParams.has("utm_medium")) {
      this.utm_medium = urlParams.get("utm_medium");
    }
    if (urlParams.has("utm_content")) {
      this.utm_content = urlParams.get("utm_content");
    }
    if (urlParams.has("utm_campaign")) {
      this.utm_campaign = urlParams.get("utm_campaign");
    }
    if (urlParams.has("utm_source")) {
      this.utm_source = urlParams.get("utm_source");
    }
    if (urlParams.has("utm_sub_partner")) {
      this.utm_sub_partner = urlParams.get("utm_sub_partner");
    }
    if (urlParams.has("utm_term")) {
      this.utm_term = urlParams.get("utm_term");
    }
    if (urlParams.has("search_origin")) {
      this.search_origin = urlParams.get("search_origin");
    }

    if (!this.$cookie.isCookieAvailable("utm_partner")) {
      this.$cookie.setCookie("utm_partner", this.utm_partner);
    } else {
      if (this.$cookie.getCookie("utm_partner") === null) {
        this.$cookie.setCookie("utm_partner", this.utm_partner);
      }
    }
    if (!this.$cookie.isCookieAvailable("utm_medium")) {
      this.$cookie.setCookie("utm_medium", this.utm_medium);
    } else {
      if (this.$cookie.getCookie("utm_medium") === null) {
        this.$cookie.setCookie("utm_medium", this.utm_medium);
      }
    }
    if (!this.$cookie.isCookieAvailable("utm_content")) {
      this.$cookie.setCookie("utm_content", this.utm_content);
    } else {
      if (this.$cookie.getCookie("utm_content") === null) {
        this.$cookie.setCookie("utm_content", this.utm_content);
      }
    }
    if (!this.$cookie.isCookieAvailable("utm_campaign")) {
      this.$cookie.setCookie("utm_campaign", this.utm_campaign);
    } else {
      if (this.$cookie.getCookie("utm_campaign") === null) {
        this.$cookie.setCookie("utm_campaign", this.utm_campaign);
      }
    }
    if (!this.$cookie.isCookieAvailable("utm_source")) {
      this.$cookie.setCookie("utm_source", this.utm_source);
    } else {
      if (this.$cookie.getCookie("utm_source") === null) {
        this.$cookie.setCookie("utm_source", this.utm_source);
      }
    }
    if (!this.$cookie.isCookieAvailable("utm_sub_partner")) {
      this.$cookie.setCookie("utm_sub_partner", this.utm_sub_partner);
    } else {
      if (this.$cookie.getCookie("utm_sub_partner") === null) {
        this.$cookie.setCookie("utm_sub_partner", this.utm_sub_partner);
      }
    }
    if (!this.$cookie.isCookieAvailable("utm_term")) {
      this.$cookie.setCookie("utm_term", this.utm_term);
    } else {
      if (this.$cookie.getCookie("utm_term") === null) {
        this.$cookie.setCookie("utm_term", this.utm_term);
      }
    }

    // when getting css api data
    // this.css_data = cssData;

    // const fpPromise = FingerprintJS.load();
    // (async () => {
    //   const fp = await fpPromise;
    //   const result = await fp.get();
    //   const visitorId = result.visitorId;
    //   this.$cookie.setCookie("_vis_fin_id", visitorId, {
    //     expire: 4320 * 60 * 60,
    //   });
    // })();

    window.addEventListener(
      "tabChanged",
      (event) => {
        this.tab_change_action(event.detail);
      },
      false
    );
    window.addEventListener(
      "tabFetchData",
      (event) => {
        console.log(event.detail);
        if (
          event &&
          event.detail &&
          event.detail.hero_image &&
          this.fetch_images.indexOf(event.detail.hero_image) === -1
        ) {
          this.fetch_images.push(event.detail.hero_image);
        }
      },
      false
    );

window.addEventListener(
"search_results_button_click",
(event) => {
encryptStorage.setItem("search_results_button_click", "1");
console.log('search_results_button_click got it', event.detail.data);
},
false
);

    setTimeout(() => {
      this.utmdata();
    }, 500);
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/normalise.scss";
@import "@/assets/sass/bodyStyle.scss";
@import "@/assets/sass/calenderOverride.scss";
@import "@/assets/sass/override_brand.scss";
@import "@/assets/sass/swalOverride.scss";
// .tab-basket {
//   display: none !important;
// }
</style>

<style scoped lang="scss">

[v-cloak] { display: none; }

@import "@/assets/sass/variables.scss";

.hero-section {
  height: auto;
  min-height: 350px;
  background: $indexpage-hero-bg;

  .hero-bg-mobile {
    min-height: 210px;
    @media screen and (max-width: 767px) {
      background: linear-gradient(
          0deg,
          var(--hero_overlay, #00000033),
          var(--hero_overlay, #00000033)
        ),
        var(--hero-background-mobile);
      background-size: cover;
      height: auto;
      position: relative;
      width: 100%;
      z-index: 0;
      transition: background-image 0.5s ease-in-out;
      
    }
  }

  .hero-bg-desktop {
    @media screen and (min-width: 768px) {
      // background: linear-gradient(
      //     0deg,
      //     var(--hero_overlay, #00000033),
      //     var(--hero_overlay, #00000033)
      //   ),
      //   var(--hero-background-desktop);
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position-x: center;
      // background-position-y: center;
      // background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-attachment: var(--hero_position, fixed);
      background-position-y: var(--hero_position_y, top);
      background-position-x: center;

      max-height: $indexpage-hero-height;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 0;
      transition: background-image 0.5s ease-in-out;
    }
  }

  .hero-overlay {
    transition: 0ms;
    background: linear-gradient(
      0deg,
      var(--hero_overlay, #00000077),
      var(--hero_overlay, #00000077)
    );
    max-height: 300px;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  .hero-text {
    position: relative;
    margin: 0 auto;
    max-width: 1260px;
    text-align: var(--hero-text-align-mobile);
    padding: 15px 15px;
    box-sizing: border-box;
    color: $indexpage-hero-text;
    text-shadow: $indexpage-hero-text-shadow;

    @media screen and (min-width: 768px) {
      text-align: var(--hero-text-align);
    }

    h1 {
      font-family: $indexpage-hero-font-h1;
      font-size: var(--hero-font-size-h1-mobile);

      @media screen and (min-width: 768px) {
        font-size: var(--hero-font-size-h1);
      }
    }

    p {
      font-family: $indexpage-hero-font-p;
      font-size: var(--hero-font-size-p-mobile);

      @media screen and (min-width: 768px) {
        font-size: var(--hero-font-size-p);
      }
    }

    h1,
    p {
      margin: 10px 0px;

      @media screen and (min-width: 768px) {
        margin: 10px;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 2.5rem 2rem;
    }
  }
}

.HER .hero-bg-desktop {
  @media screen and (min-width: 768px) {
    background-position-y: 100%;
  }
}

.SZG .hero-text {
  h1 {
    text-align: left;
  }
  p {
    text-align: left;
    font-size: 1.3rem;
  }
}

.search-widget-desktop {
  max-width: 1280px;
  margin: 0 auto;
  min-height: 142px;
  .search-widget {
    min-height: 142px;
  }
}

.hero-bg-desktop.hero-bg-desktop-fade {
  transition: opacity 0.5s ease;
}

.subheading-alt {
  font-size: 1.5rem;
}

.SV1 .hero-section,
.SV1 .page-bg {
  background: whitesmoke;
}
</style>
